import { MongoInteractionService } from 'src/services/mongo-interaction-service';
import { GetState } from 'src/reducers';
import { AppData, ReachResp, SLReachResp, SelectedCidKey } from 'src/reducers/apps.types';
import { getCurrentAppId, getCurrentAppSelectedCIDKeys } from 'src/selectors/current_app';
import { DruidService } from 'src/services/druid-service';
import { AppDataActions, AppDataDispatch, AppDataReducerAction } from './app-data.action-types';

export const FETCH_APP_DATA = 'FETCH_APP_DATA'; // by schema-interaction-service from mongo
export const FETCH_APP_DATA_SUCCESS = 'FETCH_APP_DATA_SUCCESS'; // by schema-interaction-service from mongo
export const FETCH_APP_DATA_ERROR = 'FETCH_APP_DATA_ERROR'; // by schema-interaction-service from mongo

export const app = {
  fetchData: (appId: string) => {
    return (dispatch: AppDataDispatch, _: GetState): Promise<AppData> => {
      let appData = {} as AppData;
      dispatch({ type: AppDataActions.FETCH_APP_DATA, payload: { appId }, meta: { appId } });

      const handleAppData = (appId: string, data: PromiseSettledResult<AppData>): AppData => {
        if (data.status === 'fulfilled') {
          dispatch({ type: AppDataActions.FETCH_APP_DATA_SUCCESS, payload: data.value, meta: { appId } });
          return data.value;
        }

        dispatch({ type: AppDataActions.FETCH_APP_DATA_ERROR, payload: { appId }, meta: { appId } });
        return {} as AppData;
      };

      const handleSLReachData = (appId: string, data: PromiseSettledResult<SLReachResp>): AppData['slReach'] => {
        if (data.status === 'fulfilled') {
          dispatch({ type: AppDataActions.FETCH_SL_REACH_SUCCESS, payload: data.value, meta: { appId } });
          return data.value;
        }

        dispatch({
          type: AppDataActions.FETCH_SL_REACH_ERROR,
          payload: { appId },
          meta: { appId },
          error: data.reason,
        });
        return {} as AppData['slReach'];
      };

      const handleRCReachData = (appId: string, data: PromiseSettledResult<ReachResp>): AppData['rcReach'] => {
        if (data.status === 'fulfilled') {
          dispatch({ type: AppDataActions.FETCH_RC_REACH_SUCCESS, payload: data.value, meta: { appId } });
          return data.value;
        }

        dispatch({ type: AppDataActions.FETCH_RC_REACH_ERROR, payload: { appId }, meta: { appId } });
        return {} as AppData['rcReach'];
      };

      const handleRichTextReachData = (appId: string, data: PromiseSettledResult<ReachResp>): AppData['rcReach'] => {
        if (data.status === 'fulfilled') {
          dispatch({ type: AppDataActions.FETCH_RICH_TEXT_REACH_SUCCESS, payload: data.value, meta: { appId } });
          return data.value;
        }

        dispatch({ type: AppDataActions.FETCH_RICH_TEXT_REACH_ERROR, payload: { appId }, meta: { appId } });
        return {} as AppData['rcReach'];
      };

      const getMongoAppData = MongoInteractionService.getAppData(appId);
      const getSLReach = DruidService.fetchSkipLogicReach(appId);
      const getRCReach = DruidService.fetchReachByVersion(appId, '6.6');
      const getRichTextReach = DruidService.fetchReachByVersion(appId, '6.7');

      return Promise.allSettled([getMongoAppData, getSLReach, getRCReach, getRichTextReach]).then((results) => {
        results.forEach((data, index) => {
          switch (index) {
            case 0:
              const newData = handleAppData(appId, data as PromiseSettledResult<AppData>);
              appData = { ...appData, ...newData };
              break;
            case 1:
              const slReach = handleSLReachData(appId, data as PromiseSettledResult<SLReachResp>);
              appData = { ...appData, slReach };
              break;
            case 2:
              const rcReach = handleRCReachData(appId, data as PromiseSettledResult<ReachResp>);
              appData = { ...appData, rcReach };
              break;
            case 3:
              const richTextReach = handleRichTextReachData(appId, data as PromiseSettledResult<ReachResp>);
              appData = { ...appData, richTextReach };
              break;
            default:
              break;
          }
        });

        return appData;
      });
    };
  },

  saveAppData: (data: Partial<AppData> & { id: string }) => {
    return (dispatch: AppDataDispatch, _: GetState): Promise<AppData | AppDataReducerAction> => {
      const appId = data.id;
      dispatch({ type: AppDataActions.SAVE_APP_DATA, payload: data, meta: { appId } });
      return MongoInteractionService.updateAppData(data)
        .then((resp: AppData) => {
          dispatch({ type: AppDataActions.SAVE_APP_DATA_SUCCESS, payload: resp, meta: { appId } });
          return resp;
        })
        .catch((e: Error) => {
          return dispatch({ type: AppDataActions.SAVE_APP_DATA_ERROR, payload: { appId }, error: e, meta: { appId } });
        });
    };
  },

  saveSelectedCidKeys: (keys: SelectedCidKey['name'][]) => {
    return (dispatch: AppDataDispatch, getState: GetState) => {
      const state = getState();
      const appId = getCurrentAppId(state);
      const selectedCidKeys = getCurrentAppSelectedCIDKeys(state);
      const updateCidKeys = keys.map((name) => {
        const exist = selectedCidKeys.find((item) => item.name === name);
        return exist || <SelectedCidKey>{ name };
      });
      return dispatch(app.saveAppData({ id: appId, selected_cid_keys: updateCidKeys }));
    };
  },

  setPrimaryCidKey: (primaryKey: SelectedCidKey['name']) => {
    return async (dispatch: AppDataDispatch, getState: GetState) => {
      const state = getState();
      const appId = getCurrentAppId(state);
      const selectedCidKeys = getCurrentAppSelectedCIDKeys(state);
      const newCidKeys = selectedCidKeys
        .filter((key) => !key.primary)
        .map((key) => ({ ...key, primary: key.name === primaryKey }));
      if (!newCidKeys.find((item) => item.name === primaryKey)) {
        newCidKeys.unshift({
          name: primaryKey,
          primary: true,
        });
      }

      dispatch({
        type: AppDataActions.SET_APP_PRIMARY_CID_KEY,
        payload: { appId, selected_cid_keys: newCidKeys },
      });

      const resp = await dispatch(app.saveAppData({ id: appId, selected_cid_keys: newCidKeys }));
      return resp.type !== AppDataActions.SAVE_APP_DATA_ERROR
        ? dispatch({
            type: AppDataActions.SET_APP_PRIMARY_CID_KEY_SUCCESS,
            payload: { appId, ...resp },
          })
        : dispatch({
            type: AppDataActions.SET_APP_PRIMARY_CID_KEY_ERROR,
            payload: resp.payload,
            error: resp.error,
          });
    };
  },
};
