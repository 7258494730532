import { any, isEmpty, pick, sortBy, values } from 'ramda';
import { getTextFromHtml, getIsStringContainHtml } from 'src/components/molecules/util-handlers';
import { NoteItem, NoteActionType, NoteAction, ImageLayout } from 'src/reducers/notes/notes.types';
import { API_VERSION_WITH_NO_RICH_TEXT_PROMPTS, API_VERSION_WITH_RICH_TEXT } from 'src/types/core';
import { eventNameToHuman } from 'src/utils/events';

export const WEB_IMAGE_SCALE = 1;
export const MOBILE_IMAGE_SCALE = 3;

const defaultImage: NoteItem['image'] = {
  file: '',
  title: '',
  layout: ImageLayout.FULL_WIDTH,
  alt_text: '',
  scale: MOBILE_IMAGE_SCALE,
};

const defaultModel: NoteItem = {
  active: false,
  can_launch: false,
  code_points: [],
  actions: [
    {
      label: 'Dismiss',
      interaction_type: NoteActionType.Dismiss,
      order: 0,
    },
  ],
  name: '',
  title: '',
  body: '',
  image: undefined,
  max_height: undefined,
};

export const isAction = {
  Close: (action: NoteAction) => action.interaction_type === NoteActionType.Cancel,
  Dismiss: (action: NoteAction) => action.interaction_type === NoteActionType.Dismiss,
  Survey: (action: NoteAction) => action.interaction_type === NoteActionType.Survey,
  AlchemerSurvey: (action: NoteAction) => action.interaction_type === NoteActionType.AlchemerSurvey,
  Link: (action: NoteAction) => action.interaction_type === NoteActionType.Link,
  MessageCenter: (action: NoteAction) => action.interaction_type === NoteActionType.MessageCenter,
  NilClass: (action: NoteAction) => action.interaction_type === NoteActionType.NilClass,
};

export class NoteModel {
  static Defaults = defaultModel;
  static DefaultImage = defaultImage;
  static sortActionsByOrder = sortActionsByOrder;

  static getIsContainHtmlFields(model: NoteItem): boolean {
    return getIsStringContainHtml(model.title) || getIsStringContainHtml(model.body);
  }

  static getParsedHtmlFieldsBeforeSave(model: NoteItem): NoteItem {
    return {
      ...model,
      title: getIsStringContainHtml(model.title) ? model.title : (getTextFromHtml(model.title) as string),
      body: getIsStringContainHtml(model.body) ? model.body : (getTextFromHtml(model.body) as string),
    };
  }

  static getInitialSection(model?: NoteItem): 'targeting' | 'reporting' | 'content' {
    const isNoteJustCreated = localStorage.getItem('isNoteJustCreated');
    if (isNoteJustCreated) {
      localStorage.removeItem('isNoteJustCreated');
      return 'targeting';
    }

    return model && this.hasAnswers(model) ? 'reporting' : 'content';
  }

  static getApiVersion(model: NoteItem): string {
    return this.getIsContainHtmlFields(model) ? API_VERSION_WITH_RICH_TEXT : API_VERSION_WITH_NO_RICH_TEXT_PROMPTS;
  }

  // README: https://github.com/apptentive/pupum/blob/0145af596ea3a6375aa44563fda2c65351374219/legacy/assets/views/notes/index.js#L97
  static invokeEvents({ code_points }: NoteItem): string[] {
    if (Array.isArray(code_points)) {
      return (code_points || []).map((code) => eventNameToHuman(code));
    }
    if (typeof code_points === 'string') {
      return [eventNameToHuman(code_points)];
    }
    return [];
  }

  static isValid(model: NoteItem): boolean {
    const requiredProps = pick(['name', 'actions'], model);
    const hasContent = !!(model.title || model.body || model.image);

    return !any(isEmpty)(values(requiredProps)) && hasContent;
  }

  static setData(model: NoteItem, data: Partial<NoteItem>): NoteItem {
    return { ...model, ...data };
  }

  static setImageData(model: NoteItem, data: Partial<NoteItem['image']>): NoteItem {
    return {
      ...model,
      image: model.image ? ({ ...model.image, ...data } as NoteItem['image']) : ({ ...data } as NoteItem['image']),
    };
  }

  static removeAction(model: NoteItem, pos: number): NoteItem {
    if (model.id) {
      model.actions.splice(pos, 1, { ...model.actions[pos], _destroy: 1 });
    } else {
      model.actions.splice(pos, 1);
    }
    return {
      ...model,
      actions: [...model.actions],
    };
  }

  static getActions(model: Pick<NoteItem, 'actions'>): NoteAction[] {
    return sortActionsByOrder(model.actions.filter((item) => !item._destroy).filter((item) => !isAction.Close(item)));
  }

  // https://github.com/apptentive/pupum/blob/0145af596ea3a6375aa44563fda2c65351374219/legacy/assets/views/notes/tab_reporting.js#L79
  // https://github.com/apptentive/pupum/blob/0145af596ea3a6375aa44563fda2c65351374219/legacy/assets/views/notes/tab_reporting.js#L57
  static getReportedActions(model: NoteItem): NoteAction[] {
    const sortByCount = (actions: NoteItem['actions']) => sortBy((item) => -(item.exec_count || 0), actions);
    return sortByCount(model.actions.filter((item) => (item.exec_count || 0) > 0));
  }

  static hasImageFile(image: NoteItem['image']): boolean {
    return Boolean(image?.file);
  }

  static isImageAlreadyUploaded(image: NoteItem['image']): boolean {
    return this.hasImageFile(image) && Boolean(typeof image?.file === 'string');
  }

  static hasAnswers(model: NoteItem): boolean {
    return !isEmpty(this.getReportedActions(model));
  }

  static isActiveAction(action: NoteAction): boolean {
    return action._destroy ? false : !isAction.Close(action);
  }

  static getActionsSurveyIds(model: NoteItem): string[] {
    return model.actions
      .filter((action) => action.label && action.label.toLowerCase() === 'survey')
      .map((action) => action.interaction_id) as string[];
  }

  static hasInvalidActions(model: NoteItem, surveys: { id?: string }[]): boolean {
    return model.actions.some(
      (action) =>
        action.interaction_type === NoteActionType.Survey &&
        !surveys.some(({ id }: { id?: string }) => id === action.interaction_id),
    );
  }

  static convertNoteToPayload(note: NoteItem): NoteItem {
    const actions = note.actions.map(
      (action): NoteAction =>
        isAction.AlchemerSurvey(action)
          ? {
              ...action,
              interaction_type: NoteActionType.Link,
            }
          : action,
    );

    return { ...note, actions };
  }

  static convertPayloadToNote(note: NoteItem): NoteItem {
    const actions = note.actions.map(
      (action): NoteAction =>
        action.append_variables
          ? {
              ...action,
              interaction_type: NoteActionType.AlchemerSurvey,
            }
          : action,
    );

    return { ...note, actions };
  }
}

function sortActionsByOrder(actions: NoteAction[]) {
  return sortBy((a) => (a.order !== undefined ? a.order : Number.MAX_VALUE), actions);
}
