export function camelCase(str: string): string {
  return str.replace(/^([A-Z])|[\s-_]+(\w)/gi, (match, p1, p2) => {
    if (p2) {
      return p2.toUpperCase();
    }
    return p1.toLowerCase();
  });
}

export function camelCaseKeys(obj: any): any {
  if (!obj) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((val: any) => camelCaseKeys(val));
  }

  if (typeof obj !== 'object') {
    return obj;
  }

  const keys = Object.keys(obj);

  const mappedObject: any = {};
  keys.forEach((key) => {
    mappedObject[camelCase(key)] = camelCaseKeys(obj[key]);
  });

  return mappedObject;
}

export default camelCaseKeys;
