import { InteractionItem } from 'src/types/core';

export enum NoteActionType {
  Cancel = 'cancel',
  Dismiss = 'dismiss',
  Link = 'NavigateToLink',
  Survey = 'Survey',
  MessageCenter = 'MessageCenterV2',
  NilClass = 'NilClass', // issue from WEB, when delete survey used in note action
  AlchemerSurvey = 'AlchemerSurvey',
}

export enum ImageLayoutTitle {
  FULL_WIDTH = 'Full Width', // default
  CENTER = 'Center',
  LEFT = 'Align Left',
  RIGHT = 'Align Right',
}

export enum ImageLayout {
  FULL_WIDTH = 'full_width', // default
  CENTER = 'center',
  LEFT = 'align_left',
  RIGHT = 'align_right',
}

export interface NoteHeaderFile {
  file: any;
  title: string;
  layout: ImageLayout;
  alt_text: string;
  scale?: number;
}

export type NoteLinkAppendedVariables = 'conversation_id';

export interface NoteAction {
  id?: string;
  label: string;
  exec_count?: number;
  interaction_type: NoteActionType;
  interaction_id?: string;
  url?: string;
  order?: number;
  target?: 'new' | 'self';
  append_variables?: NoteLinkAppendedVariables[];
  _destroy?: 1;
}

export interface NoteItem extends InteractionItem {
  id?: string;
  name: string;
  title?: string;
  body?: string;
  actions: NoteAction[];
  app_id?: string;
  archived_actions?: NoteAction[];
  can_launch: boolean;
  display_type?: null | 'notification';
  first_active_at?: string;
  updated_at?: string;
  view_count?: number;
  image?: NoteHeaderFile;
  max_height?: number;
  min_api_version?: number;
}
