export const isDevMode = () => process.env.NODE_ENV === 'development';
export const isTestMode = () => process.env.NODE_ENV === 'test';
export const isDemoEnv = () => process.env.DEMO_ENV === 'true';
export const isMockApi = () => process.env.MOCK_API === 'true';

// Netlify environment variables
export const isNetlifyDev = () => process.env.NETLIFY_ENV === 'development';
export const isNetlifyStaging = () => process.env.NETLIFY_ENV === 'staging';
export const isNetlifyProd = () => process.env.NETLIFY_ENV === 'production';

export const isEURegion = () => process.env.HOST_NAME === 'be.digital.alchemer.eu';
