import 'mdn-polyfills/Array.prototype.includes';
import { combineEpics } from 'redux-observable';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

import { MongoInteractionService } from 'src/services';
import JWT from '../utils/jwt';
import fullPath from '../utils/full_path';
import SavedLocation from '../utils/saved_location';
import debugLog from '../utils/debug_log';
import { fetchApp } from './apps';
import { fetchCurrentUserFeatures } from './index';
import { getCurrentAppId } from '../selectors/current_app';
import { getUser, getCurrentUserId, getUiNumNewDownloads } from '../selectors/user';
import { clearDownloadCenterWelcome, clearFsExportInfoModal } from './ui';

export const UPDATE_CURRENT_APP_PENDING = 'UPDATE_CURRENT_APP_PENDING';
export const updateCurrentAppPending = (appId) => ({
  type: UPDATE_CURRENT_APP_PENDING,
  payload: { appId },
  meta: { appId },
});
export const UPDATE_CURRENT_APP_SUCCESS = 'UPDATE_CURRENT_APP_SUCCESS';
export const updateCurrentAppSuccess = (appId, _jwt) => ({
  type: UPDATE_CURRENT_APP_SUCCESS,
  // Intentionally not passing the _jwt argument in the payload. This avoids updating the
  // user's jwt when switching apps. This behavior does not apply with Auth0 jwts.
  payload: { appId },
  meta: { appId },
});
export const UPDATE_CURRENT_APP_FAILED = 'UPDATE_CURRENT_APP_FAILED';
export const updateCurrentAppError = (appId, error) => ({
  type: UPDATE_CURRENT_APP_FAILED,
  payload: error,
  meta: { appId },
  error: true,
});

export const SET_UI_STATE_PENDING = 'SET_UI_STATE_PENDING';
export const SET_UI_STATE_SUCCESS = 'SET_UI_STATE_SUCCESS';
export const SET_UI_STATE_ERROR = 'SET_UI_STATE_ERROR';

const FETCH_SESSION_PENDING = 'FETCH_SESSION_PENDING';
const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
const FETCH_SESSION_ERROR = 'FETCH_SESSION_ERROR';
const REDIRECTING_TO_LOADING_PAGE = 'REDIRECTING_TO_LOADING_PAGE';

export const updateCurrentApp = (appId, backButton = false) => (dispatch, getState, { api } = {}) => {
  if (!appId || appId === 'new' || appId === 'current') {
    debugLog('Unable to update current app to', appId);
    return false;
  }
  const state = getState();
  const currentAppId = getCurrentAppId(state);
  if (backButton && appId === currentAppId) {
    debugLog('Current app already up to date', currentAppId);
    return false;
  }

  dispatch({ type: 'APP_DRAWER_CLOSE' });
  dispatch(updateCurrentAppPending(appId));
  return api.updateCurrentApp(appId)
    .then(json => dispatch(updateCurrentAppSuccess(appId, json.token)))
    .catch(error => {
      dispatch(updateCurrentAppError(appId, error));
      throw error;
    });
};

export const REQUEST_USER = 'REQUEST_USER';
export const requestUser = (userId) => ({ type: REQUEST_USER, payload: { userId } });

export const RECEIVE_USER = 'RECEIVE_USER';
export const receiveUser = (id, json) => ({
  type: RECEIVE_USER,
  payload: {
    userId: json.id,
    name: json.name,
    email: json.email,
    currentApp: json.current_app_id,
    uiState: json.ui_state,
    companyTelephone: json.company_telephone,
    professionalRole: json.professional_role,
    timeZone: json.time_zone,
    surveyReportSubIds: json.survey_report_sub_ids,
  },
  meta: {
    appId: json.current_app_id,
  },
});

export const SKIPPING_USER_FETCH = 'SKIPPING_USER_FETCH';
export const skippingUserFetch = (json) => ({
  type: SKIPPING_USER_FETCH,
  userId: json.id,
  name: json.name,
  email: json.email,
  currentApp: json.current_app_id,
  uiState: json.ui_state,
});

export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';
export const fetchCurrentUserFailure = (error) => ({
  type: FETCH_CURRENT_USER_FAILURE,
  payload: error,
  meta: {},
});

// export function fetchCurrentUser() {
export const fetchCurrentUser = (override = false) => (dispatch, getState, { api }) => {
  const state = getState();
  const userId = getCurrentUserId(state);
  if (!userId || override) {
    dispatch(requestUser('current'));
    return api.fetchCurrentUser()
      .then(json => dispatch(receiveUser('current', json)))
      .catch(ex => {
        dispatch(fetchCurrentUserFailure(ex.message));

        // Save the current location so we can redirect the user to the correct page after login
        if (!SavedLocation.check()) {
          SavedLocation.set(fullPath());
        }

        console.warn('Redirecting due to', ex);
        JWT.clear();
        window.location.assign(fullPath('/login'));
      });
  }

  // TODO: More idiomatic Promise approach
  const user = getUser(state);
  dispatch(skippingUserFetch(user));
  return Promise.resolve(user);
};

export const setUIStatePending = (key, value) => ({ type: SET_UI_STATE_PENDING, payload: { key, value } });
export const setUIStateSuccess = (key, value, payload) => ({ type: SET_UI_STATE_SUCCESS, payload: { key, value, token: (payload && payload.token) ? payload.token : '' } });
export const setUIStateError = (key, value, error) => ({ type: SET_UI_STATE_ERROR, payload: error, error: true, meta: { key, value } });
export const setUIState = (key, value) => (dispatch, _getState, { api }) => {
  dispatch(setUIStatePending(key, value));
  return api.setUIState(key, value)
    .then(payload => dispatch(setUIStateSuccess(key, value, payload)))
    .catch(error => dispatch(setUIStateError(key, value, error)));
};

export const updateAppEpic = () => fromEvent(window, 'popstate').pipe(
  map((ev) => {
    if (ev && ev.state && ev.state.appId) {
      return updateCurrentApp(ev.state.appId, true);
    }
    // NOTE: No appId in event state, guess from pathname
    const pieces = window.location.pathname.split('/');
    if (pieces.length > 2 && pieces[1] === 'apps' && pieces[2] !== 'current') {
      return updateCurrentApp(pieces[2], true);
    }
    console.error('Attempted to call updateAppEpic without an appId', ev);
    return { type: 'NOOP' };
  })
);

export const epics = combineEpics(updateAppEpic);

export function fetchSession() {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_SESSION_PENDING });

    return dispatch(fetchCurrentUser())
      .then(() => {
        dispatch({ type: FETCH_SESSION_SUCCESS });
        const state = getState();
        const currentAppId = getCurrentAppId(state);
        const currentUserId = getCurrentUserId(state);
        if (currentAppId) {
          dispatch(fetchApp(currentAppId));
          dispatch(fetchCurrentUserFeatures(currentUserId));
        } else {
          dispatch({ type: REDIRECTING_TO_LOADING_PAGE });
          window.location.assign(fullPath('/loading'));
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_SESSION_ERROR, payload: error, error: true });
      });
  };
}

export const updateUIState = (key, value) => async (dispatch, getState) => {
  const state = getState();
  const currentUserId = getCurrentUserId(state);

  dispatch(setUIStateSuccess(key, value));
  MongoInteractionService.updateUserUIState(currentUserId, { [key]: value });
};

export const resetNewDownloadsCount = () => async (dispatch, getState) => {
  const numNewDownloads = getUiNumNewDownloads(getState());

  if (numNewDownloads !== 0) {
    dispatch(clearFsExportInfoModal());
    dispatch(updateUIState('numNewDownloads', 0));
  }
};

export const acknowledgeDownloadCenterWelcome = () => async (dispatch) => {
  dispatch(clearDownloadCenterWelcome());
  dispatch(updateUIState('has_seen_download_center', true));
};

export const acknowledgeFsExportInfoModal = () => async (dispatch) => {
  dispatch(clearFsExportInfoModal());
  dispatch(updateUIState('has_seen_fs_export_info', true));
};
